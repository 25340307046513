exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ab-exp-tsx": () => import("./../../../src/pages/abExp.tsx" /* webpackChunkName: "component---src-pages-ab-exp-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-health-tsx": () => import("./../../../src/pages/appHealth.tsx" /* webpackChunkName: "component---src-pages-app-health-tsx" */),
  "component---src-pages-auto-optimizations-tsx": () => import("./../../../src/pages/autoOptimizations.tsx" /* webpackChunkName: "component---src-pages-auto-optimizations-tsx" */),
  "component---src-pages-call-center-tsx": () => import("./../../../src/pages/callCenter.tsx" /* webpackChunkName: "component---src-pages-call-center-tsx" */),
  "component---src-pages-call-enrichment-tsx": () => import("./../../../src/pages/callEnrichment.tsx" /* webpackChunkName: "component---src-pages-call-enrichment-tsx" */),
  "component---src-pages-call-sms-solutions-tsx": () => import("./../../../src/pages/callSMSSolutions.tsx" /* webpackChunkName: "component---src-pages-call-sms-solutions-tsx" */),
  "component---src-pages-cloud-solutions-tsx": () => import("./../../../src/pages/cloudSolutions.tsx" /* webpackChunkName: "component---src-pages-cloud-solutions-tsx" */),
  "component---src-pages-custom-api-tsx": () => import("./../../../src/pages/customAPI.tsx" /* webpackChunkName: "component---src-pages-custom-api-tsx" */),
  "component---src-pages-custom-call-tsx": () => import("./../../../src/pages/customCall.tsx" /* webpackChunkName: "component---src-pages-custom-call-tsx" */),
  "component---src-pages-custom-websites-tsx": () => import("./../../../src/pages/customWebsites.tsx" /* webpackChunkName: "component---src-pages-custom-websites-tsx" */),
  "component---src-pages-data-enrichment-tsx": () => import("./../../../src/pages/dataEnrichment.tsx" /* webpackChunkName: "component---src-pages-data-enrichment-tsx" */),
  "component---src-pages-data-science-tsx": () => import("./../../../src/pages/dataScience.tsx" /* webpackChunkName: "component---src-pages-data-science-tsx" */),
  "component---src-pages-get-quote-tsx": () => import("./../../../src/pages/getQuote.tsx" /* webpackChunkName: "component---src-pages-get-quote-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integration-solutions-tsx": () => import("./../../../src/pages/integrationSolutions.tsx" /* webpackChunkName: "component---src-pages-integration-solutions-tsx" */),
  "component---src-pages-managed-servers-tsx": () => import("./../../../src/pages/managedServers.tsx" /* webpackChunkName: "component---src-pages-managed-servers-tsx" */),
  "component---src-pages-managed-solutions-tsx": () => import("./../../../src/pages/managedSolutions.tsx" /* webpackChunkName: "component---src-pages-managed-solutions-tsx" */),
  "component---src-pages-mobile-apps-tsx": () => import("./../../../src/pages/mobileApps.tsx" /* webpackChunkName: "component---src-pages-mobile-apps-tsx" */),
  "component---src-pages-offline-marketing-tsx": () => import("./../../../src/pages/offlineMarketing.tsx" /* webpackChunkName: "component---src-pages-offline-marketing-tsx" */),
  "component---src-pages-properties-tsx": () => import("./../../../src/pages/properties.tsx" /* webpackChunkName: "component---src-pages-properties-tsx" */),
  "component---src-pages-real-time-webhooks-tsx": () => import("./../../../src/pages/realTimeWebhooks.tsx" /* webpackChunkName: "component---src-pages-real-time-webhooks-tsx" */),
  "component---src-pages-scrapping-tsx": () => import("./../../../src/pages/scrapping.tsx" /* webpackChunkName: "component---src-pages-scrapping-tsx" */),
  "component---src-pages-server-to-server-tsx": () => import("./../../../src/pages/serverToServer.tsx" /* webpackChunkName: "component---src-pages-server-to-server-tsx" */),
  "component---src-pages-stats-tsx": () => import("./../../../src/pages/stats.tsx" /* webpackChunkName: "component---src-pages-stats-tsx" */)
}

